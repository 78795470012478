import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTextField,{attrs:{"label":"Color","readonly":"","hint":"click left on the box"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c(VMenu,{attrs:{"top":"","nudge-bottom":"100","nudge-left":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"elevation-1",style:(_vm.swatchStyle)},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{model:{value:(_vm.config.clockColor),callback:function ($$v) {_vm.$set(_vm.config, "clockColor", $$v)},expression:"config.clockColor"}})],1)],1)],1)]},proxy:true}]),model:{value:(_vm.config.clockColor),callback:function ($$v) {_vm.$set(_vm.config, "clockColor", $$v)},expression:"config.clockColor"}})}
var staticRenderFns = []

export { render, staticRenderFns }
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VNavigationDrawer,{attrs:{"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VImg,{staticClass:"mt-3",attrs:{"max-height":"100","src":require("./assets/logo.png"),"contain":""}}),_c('nav-links',{attrs:{"items":_vm.getNavLinks}})],1),_c(VAppBar,{attrs:{"app":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c(VToolbarTitle,[_c('b',[_vm._v("PixelIt")]),_vm._v(" - The Matrix Display")]),_c(VSpacer,[(!_vm.sockedIsConnected && !_vm.isDemoMode)?_c(VToolbarTitle,{staticClass:"text-center message"},[_vm._v("Reconnecting...")]):_vm._e()],1),(_vm.displayHostname != '')?_c('div',{staticClass:"hostname padded",attrs:{"title":("Hostname: " + (this.displayHostname))},domProps:{"textContent":_vm._s(_vm.displayHostname)}}):_vm._e(),(_vm.sockedIsConnected)?_c(VIcon,{staticClass:"padded",attrs:{"color":"green","title":("Connected to " + (this.$socket.url))}},[_vm._v("mdi-lan-connect ")]):_vm._e(),(_vm.isDemoMode)?_c(VIcon,{staticClass:"padded",attrs:{"color":"green","title":"Connected to demo data source"}},[_vm._v("mdi-lan-connect")]):_vm._e(),(!_vm.sockedIsConnected && !_vm.isDemoMode)?_c(VIcon,{staticClass:"padded",attrs:{"color":"red","title":("Disconnected from " + (this.$socket.url))}},[_vm._v("mdi-lan-disconnect")]):_vm._e(),_c(VBtn,{attrs:{"icon":"","title":"Change theme"},on:{"click":_vm.changeTheme}},[(_vm.darkModeActive)?_c(VIcon,[_vm._v("mdi-brightness-4")]):_c(VIcon,[_vm._v("mdi-brightness-4")])],1)],1),_c(VMain,{attrs:{"app":""}},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"gallery"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[(!_vm.isLoading)?_c(VTextField,{attrs:{"prepend-inner-icon":"mdi-magnify","single-line":"","outlined":"","filled":"","hide-details":"","auto-grow":"","label":"Search for name or ID","type":"text"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}):_vm._e()],1),(_vm.isLoading)?_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c('fold',{attrs:{"loading":_vm.isLoading}}),_vm._v(" Loading... ")],1):_vm._e(),_vm._l((_vm.getBMPs),function(bmp){return _c(VCol,{key:bmp.id,attrs:{"cols":"6","lg":"2"}},[_c(VCard,{staticClass:"pa-2",attrs:{"elevation":"4"}},[_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(bmp.name)+" ")]),_c('hr'),_c('p'),_c('BmpCanvas',{attrs:{"bmp":bmp}}),_c('p'),_c('div',{staticClass:"text-center"},[_c('BmpDialog',{attrs:{"bmp":bmp}}),_c(VBtn,{staticClass:"float-right",attrs:{"icon":"","disabled":!_vm.sockedIsConnected,"title":"Show on PixelIt"},on:{"click":function($event){return _vm.sendBitmap(bmp.rgB565Array, bmp.sizeX)}}},[_c(VIcon,[_vm._v("mdi-arrow-right-circle-outline")])],1)],1),_c('div',{staticClass:"text-center"},[_c(VTextField,{attrs:{"prepend-inner-icon":"mdi-identifier","rounded":"","dense":"","hide-details":"","readonly":"","value":bmp.id,"append-outer-icon":"mdi-content-copy"},on:{"click:append-outer":function($event){return _vm.copyText(bmp.id, $event)}}})],1)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,_vm._l((_vm.items),function(item){return _c('div',{key:item.title},[(item.separator == true)?_c(VListItem,{key:item.title,attrs:{"disabled":"","link":"","to":item.page,"href":item.url,"target":item.target}},[_c(VDivider)],1):_vm._e(),(item.separator != true)?_c(VListItem,{key:item.title,attrs:{"link":"","to":item.page,"href":item.url,"target":item.target}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }
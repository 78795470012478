import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"float-left",attrs:{"icon":"","title":"More information"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-information-outline")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.bmp.name)+" ")])]),_c(VDivider),_c(VCardText,[_c('BmpCanvas',{staticClass:"float-right",attrs:{"bmp":_vm.bmp,"height":"80","width":_vm.bmp.sizeX == 8 ? '80' : '160'}}),_c('p'),_vm._v(" Icon-ID: "+_vm._s(_vm.bmp.id)),_c('br'),_vm._v(" Added by: "+_vm._s(_vm.bmp.username)),_c('br'),_vm._v(" Added on: "+_vm._s(_vm.bmp.dateTime.split('T')[0])),_c('br'),_vm._v(" Animated: "+_vm._s(_vm.bmp.animated ? 'Yes' : 'No')),_c('br'),_c('br'),_c(VTextarea,{attrs:{"filled":"","outlined":"","readonly":"","rows":"8"},model:{value:(_vm.bmp.rgB565Array),callback:function ($$v) {_vm.$set(_vm.bmp, "rgB565Array", $$v)},expression:"bmp.rgB565Array"}})],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" close ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
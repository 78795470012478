import { VBadge } from 'vuetify/lib/components/VBadge';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.items),function(item){return _c(VList,{key:item.version,staticClass:"pa-2"},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"pointer",on:{"click":function($event){return _vm.gotToURL(item.downloadURL)}}},[_c(VBadge,{attrs:{"color":"green","content":item.downloads,"inline":""}},[_c('h3',[_vm._v(_vm._s(item.version)+" ("+_vm._s(item.date)+")")])])],1),_c('span',{staticClass:"header"},[_vm._v("Downloads:")]),_c('br'),_vm._l((item.fwdownloads),function(fwdownload){return _c(VListItemSubtitle,{key:fwdownload.name,staticClass:"details"},[_vm._v(" • "+_vm._s(fwdownload.name)+" "),_c('span',{staticClass:"downloads"},[_vm._v(_vm._s(fwdownload.downloads))])])}),_c('span',{staticClass:"header"},[_vm._v("Release notes:")]),_c('br'),_vm._l((item.releaseNoteArray),function(releaseNote){return _c(VListItemSubtitle,{key:releaseNote,staticClass:"details"},[_vm._v(" • "+_vm._s(releaseNote)+" ")])})],2),_c(VDivider,{attrs:{"inset":false}})],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }
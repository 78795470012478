import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"450"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":_vm.color,"dark":"","disabled":!_vm.condition}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v(_vm._s(_vm.icon))]),_vm._v(" "+_vm._s(_vm.text)+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._l((_vm.cardText),function(cText){return _c(VCardText,{key:cText},[_vm._v(_vm._s(cText))])}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.disagreeWraper, (_vm.dialog = false)}}},[_vm._v(" "+_vm._s(_vm.disagreeText)+" ")]),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.agreeWraper}},[_vm._v(" "+_vm._s(_vm.agreeText)+" ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
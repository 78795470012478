import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"450"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":_vm.color,"dark":"","disabled":!_vm.condition}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v(_vm._s(_vm.icon))]),_vm._v(" "+_vm._s(_vm.text)+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VForm,{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Save Bitmap? ")]),_c(VCardText,[_c(VTextField,{ref:"field",attrs:{"label":"Bitmap Name","rules":[_vm.rules.required, _vm.rules.notStartsWithSpace]},model:{value:(_vm.bitmapName),callback:function ($$v) {_vm.bitmapName=$$v},expression:"bitmapName"}}),_c(VTextField,{attrs:{"label":"Your Username","rules":[_vm.rules.notStartsWithSpace]},on:{"change":_vm.changeUserName},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green darken-1","text":"","disabled":((!_vm.isValid) || (_vm.getSizeX == 32 && _vm.isAnimated == true))},on:{"click":_vm.agreeWraper}},[_vm._v(" Save into Database ")]),_c(VBtn,{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.disagreeWraper}},[_vm._v(" Abort ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }